import React, {useEffect, useState} from "react";
import {langVar} from "../../index";
import {Link} from "react-router-dom";
import {useDirectus} from "react-directus";
import DynamicFaIcon from "../utilities/DynamicFaIcon";

const Footer = () => {

    const [content, setContent] = useState([]);
    const {directus} = useDirectus();

    useEffect(() => {
        const fetchFooterIconData = async () => {
            return await directus.items("footer_icon").readByQuery({
                limit: -1,
                sort: "sort"
            });
        }

        fetchFooterIconData()
            .then(result => {
                if (result.data)
                    setContent(result.data);
            });
    }, [directus]);

    return (
        <>
            <div className="row align-items-center">
                <div className="col-md-6 my-2">
                    <div className="nav justify-content-center justify-content-md-start">
                        {content.map((val, i) => (
                            <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                                <DynamicFaIcon name={val.icon}/>
                            </a>
                        ))}
                    </div>
                </div>

                <div className="col-md-6 my-2 text-center text-md-end">
                    <p>
                        <Link to="/legal-notice">{langVar("app.legalNotice")}</Link>&nbsp;&nbsp;
                        <Link to="/privacy-policy">{langVar("app.privacyPolicy")}</Link>
                    </p>
                </div>
            </div>
        </>
    );
};

export default Footer;

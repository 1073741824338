import React from "react";
import {langVar} from "../index";

interface ErrorProps {
    message?: string;
}

const Error = ({message}: ErrorProps) => {
    document.body.classList.remove("theme-light");

    if (message === undefined)
        message = langVar("pageNotFound");

    return (
        <>
            <section className="error-page-wrapper">
                <div className="container">
                    <div className="row justify-content-center full-screen align-items-center">
                        <div className="col-lg-8 text-center">
                            <div className="inner">
                                <h1 className="display-3 white-color m-15px-b">
                                    ERROR
                                </h1>
                                <p className="h4">
                                    {message}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Error;

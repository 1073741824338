import React, {useEffect} from "react";
import {DATACENTER, getStoreValue, langVar, langVarPrintHtml} from "../../index";
import {useDirectus} from "react-directus";
import Service from "../service/Service";
import Awards from "../awards/Awards";

const About = () => {

    const conctInfo = {
        phone: getStoreValue("settings", "CONTACT_PHONE"),
        email: getStoreValue("settings", "CONTACT_EMAIL"),
        age: getStoreValue("settings", "PERSONAL_AGE"),
        city: getStoreValue("settings", "PERSONAL_CITY")
    };

    const {directus} = useDirectus();

    useEffect(() => {
        const fetchHeaderImage = async () => {
            return await directus.files.readByQuery({
                filter: {
                    "title": {
                        "_eq": "about"
                    }
                }
            });
        }

        fetchHeaderImage()
            .then(result => {
                if (result.data && result.data.length === 1)
                    document.getElementById("about-image").src =
                        `${DATACENTER}/assets/${result.data[0].id}?quality=50`
            });
    }, [directus]);

    return (
        <>
            <section id="about" className="section theme-light dark-bg">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div
                            className="col-md-6 col-lg-4"
                            data-aos="fade-up"
                            data-aos-duration="1200">
                            <div className="about-me">
                                <div className="img">
                                    <div className="img-in">
                                        <img id="about-image" alt=""/>
                                    </div>
                                </div>
                                <div className="info">
                                    <p>{langVar("0.header.title")}</p>
                                    <h3>{langVar("0.header.name")}</h3>
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-lg-7 ml-auto"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-delay="150">
                            <div className="about-info">
                                <div className="title">
                                    <h3>{langVar("0.biography.headline")}</h3>
                                </div>
                                <div className="about-text"
                                     dangerouslySetInnerHTML={{__html: langVarPrintHtml("0.biography.text")}}></div>
                                <div className="info-list">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <ul>
                                                <li>
                                                    <label>{langVar("name")}: </label>
                                                    <span>{langVar("0.header.name")}</span>
                                                </li>
                                                <li>
                                                    <label>{langVar("city")}: </label>
                                                    <span>{conctInfo.city}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6">
                                            <ul>
                                                <li>
                                                    <label>{langVar("settings.CONTACT_PHONE")}: </label>
                                                    <span>{conctInfo.phone}</span>
                                                </li>
                                                <li>
                                                    <label>{langVar("settings.CONTACT_EMAIL")}: </label>
                                                    <span>{conctInfo.email}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="separated"
                         style={{
                             backgroundImage: `url(${
                                 process.env.PUBLIC_URL + "img/border-dark.png"
                             })`
                         }}></div>

                    <div className="title">
                        <h3 dangerouslySetInnerHTML={{__html: langVarPrintHtml("0.services")}}></h3>
                    </div>

                    <Service/>

                    <div className="separated"
                         style={{
                             backgroundImage: `url(${
                                 process.env.PUBLIC_URL + "img/border-dark.png"
                             })`,
                         }}></div>

                    <div className="title">
                        <h3 dangerouslySetInnerHTML={{__html: langVarPrintHtml("0.awardsCertificates")}}></h3>
                    </div>

                    <Awards/>
                </div>
            </section>
        </>
    );
};

export default About;

import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/scss/main.scss";
import React, {useEffect, useState} from "react";
import {useDirectus} from "react-directus";
import {DATACENTER, getActiveLanguageID, getStore, getStoreValue, getUser, logout, setStoreValue} from "./index";
import {DirectusContextTpye} from "react-directus/dist/types/types";
import {PartialItem} from "@directus/sdk";
import RouteManager from "./RouteManager";
import Loader from "./components/utilities/Loader";
import useDocumentTitle from "./components/utilities/useDocumentTitle";

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        AOS.init();
    }, []);

    // @ts-ignore
    const {directus}: DirectusContextTpye = useDirectus();

    useEffect(() => {
        const fetchSettingData = async () => {
            return await directus.items("settings").readByQuery({
                limit: -1
            });
        }

        const fetchLanguageData = async () => {
            return await directus.items("language").readByQuery({
                limit: -1
            });
        }

        const fetchLanguageVariableData = async () => {
            return await directus.items("language_variable").readByQuery({
                limit: -1,
                filter: {
                    "language": {
                        _in: [getActiveLanguageID()]//, getStoreValue("settings", "DEFAULT_LANGUAGE_ID")]
                    }
                }
            });
        }

        const loadUserInformation = async () => {
            if (directus.auth.token) {
                directus.users.me.read()
                    .then(async user => {
                        user.role = await directus.roles.readOne(user.role);
                        setStoreValue("user", JSON.stringify(user));
                        getStore().saveStateToLocalStorage("user", getStoreValue("user"));
                        if (getUser()) localStorage.setItem("selected_language", getUser().languageIdentifier);
                    })
                    .catch(() => {
                        logout();
                    });
            } else {
                setStoreValue("user", null);
                getStore().deleteStateFromLocalStorage("user");
            }
        }

        const fetchFavicon = async () => {
            return await directus.files.readByQuery({
                filter: {
                    "title": {
                        "_eq": "favicon"
                    }
                }
            });
        }

        fetchFavicon()
            .then(result => {
                if (result.data && result.data.length === 1) {
                    let faviconLink = document.createElement('link');
                    faviconLink.rel = 'icon';
                    faviconLink.href = DATACENTER + "/assets/" + result.data[0].id + "?quality=50"
                    document.getElementsByTagName('head')[0].appendChild(faviconLink);
                }
            });

        loadUserInformation()
            .finally(() => {
                fetchSettingData()
                    .then(result => {
                        let settings: { [id: string]: string; } = {};

                        if (result.data)
                            result.data.forEach((setting: PartialItem<any>) => {
                                settings[setting.id] = setting.value;
                            })

                        setStoreValue("settings", settings);
                    })
                    .finally(() => {
                        fetchLanguageData()
                            .then(result => setStoreValue("languages", result.data))
                            .finally(() => {
                                fetchLanguageVariableData()
                                    .then(result => {
                                        let languageVariables: { [id: string]: string; } = {};

                                        if (result.data)
                                            result.data.forEach((languageVariable: PartialItem<any>) => {
                                                languageVariables[languageVariable.key] = languageVariable.value
                                            })

                                        setStoreValue("languageVariables", languageVariables);
                                    })
                                    .finally(() => {
                                        setTimeout(() => {
                                            setLoading(false);
                                        }, 750);
                                    });
                            });
                    });
            });
    }, [directus]);

    useDocumentTitle(getStoreValue("settings", "PAGE_NAME"));

    return (
        <>
            {!loading && <>
                <RouteManager/>
            </>}
            {loading && <><Loader/></>}
        </>
    );
}

export default App;

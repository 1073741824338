import React, {useEffect, useState} from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {useDirectus} from "react-directus";
import {getActiveLanguageID} from "../../index";

const Skills = () => {

    const [content, setContent] = useState([]);
    const {directus} = useDirectus();

    useEffect(() => {
        const fetchSkillData = async () => {
            return await directus.items("skill").readByQuery({
                limit: -1,
                fields: "*.*",
                deep: {
                    "translations": {
                        _filter: {
                            "language_id": {
                                _eq: getActiveLanguageID()
                            }
                        }
                    }
                },
                sort: "sort"
            });
        }

        fetchSkillData()
            .then(result => {
                let skills = [];

                if (result.data)
                    result.data.forEach(skill => {
                        skills.push({
                            name: skill.translations.length === 1 ?
                                skill.translations[0].name : "",
                            percent: skill.percent
                        });
                    });

                setContent(skills);
            });
    }, [directus]);

    const [focus, setFocus] = React.useState(false);
    return (<>
        <div className="skill-wrapper">
            {content.map((skill, i) => (<div className="skill-lt" key={i}>
                <h6>{skill.name}</h6>
                <span className="count-inner">
                      <CountUp
                          start={focus ? "0" : null}
                          end={skill.percent}
                          duration={1}
                          redraw={true}>
                        {({countUpRef}) => (<VisibilitySensor
                            onChange={(isVisible) => {
                                if (isVisible) {
                                    setFocus(true);
                                }
                            }}>
                            <span ref={countUpRef}/>
                        </VisibilitySensor>)}
                      </CountUp>
                %
                </span>
                <div className="skill-bar">
                    <div
                        className="skill-bar-in"
                        style={{width: skill.percent + "%"}}
                    ></div>
                </div>
            </div>))}
        </div>
    </>);
};

export default Skills;

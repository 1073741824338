import React, {useEffect, useState} from "react";
import {useDirectus} from "react-directus";
import {getActiveLanguageID} from "../../index";

export default function Service() {

    const [content, setContent] = useState([]);
    const {directus} = useDirectus();

    useEffect(() => {
        const fetchServiceData = async () => {
            return await directus.items("service").readByQuery({
                limit: -1,
                fields: "*.*",
                filter: {
                    "enabled": {
                        _eq: true
                    }
                },
                deep: {
                    "translations": {
                        _filter: {
                            "language_id": {
                                _eq: getActiveLanguageID()
                            }
                        }
                    }
                },
                sort: "sort"
            });
        }

        fetchServiceData()
            .then(result => {
                let services = [];

                if (result.data)
                    result.data.forEach(service => {
                        services.push({
                            title: service.translations.length === 1
                                ? service.translations[0].title : "",
                            description: service.translations.length === 1
                                ? service.translations[0].description : ""
                        });
                    });

                setContent(services);
            });
    }, [directus]);

    return (
        <>
            <div className="row">
                {content.map((val, i) => (
                    <div
                        className="col-md-6 col-lg-4 my-3"
                        key={i}
                        data-aos="fade-right"
                        data-aos-duration="500"
                        data-aos-delay={150 * i}>
                        <div className="feature-box-01">
                            <div className="icon">
                                <i className="icon icon-paperclip"></i>
                            </div>
                            <div className="feature-content">
                                <h5>{val.title}</h5>
                                <p dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

import React, {useEffect, useState} from "react";
import {useDirectus} from "react-directus";
import {DATACENTER, getActiveLanguageID} from "../../index";

const Awards = () => {

    const [content, setContent] = useState([]);
    const {directus} = useDirectus();

    useEffect(() => {
        const fetchAwardData = async () => {
            return await directus.items("award").readByQuery({
                limit: -1,
                fields: "*.*",
                deep: {
                    "translations": {
                        _filter: {
                            "language_id": {
                                _eq: getActiveLanguageID()
                            }
                        }
                    }
                },
                sort: "sort"
            });
        }

        fetchAwardData()
            .then(result => {
                let awards = [];

                if (result.data)
                    result.data.forEach(award => {
                        awards.push({
                            name: award.translations.length === 1 ? award.translations[0].name : "",
                            for: award.translations.length === 1 ? award.translations[0].for : "",
                            image: award.image
                        });
                    });

                setContent(awards);
            });
    }, [directus]);

    return (
        <>
            <div className="row">
                {content.map((val, i) => (
                    <div
                        className="col-lg-4 m-15px-tb"
                        key={i}
                        data-aos="fade-right"
                        data-aos-duration="500"
                        data-aos-delay={150 * i}>
                        <div className="feature-box-02 d-flex align-items-center">
                            <div className="icon">
                                {val.image != null && <>
                                    <img src={`${DATACENTER}/assets/${val.image.id}`} alt="award"/>
                                </>}
                                {val.image == null &&
                                    <div className="awardIconImage"><i className="icon icon-trophy"></i></div>}
                            </div>
                            <div className="media-body">
                                <h6>{val.name}</h6>
                                <p>{val.for}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Awards;

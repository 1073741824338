import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {getStoreValue, langVar} from "../../index";

const Contact = () => {
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm();

    const onSubmit = (data, e) => {
        e.target.reset();

        fetch(getStoreValue("settings", "CONTACT_WEBHOOK"), {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(result => {
            setSent(true);
            setTimeout(() => {
                setSent(false);
            }, 5000);
        });
    };

    const [sent, setSent] = useState(false);

    return (
        <>
            {sent && <h6>{langVar("contactFormSent")}</h6>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <input
                                type="text"
                                className="form-control theme-light"
                                placeholder={langVar("name")}
                                {...register("name", {required: true})}/>
                            {errors.name && errors.name.type === "required" && (
                                <span className="invalid-feedback">{langVar("isRequired")}</span>
                            )}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <input
                                type="email"
                                className="form-control theme-light"
                                placeholder={langVar("auth.email")}
                                {...register(
                                    "email",
                                    {
                                        required: langVar("isRequired"),
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: langVar("error.notEmail")
                                        }
                                    },
                                    {required: true}
                                )}/>
                            {errors.email && (
                                <span className="invalid-feedback">{errors.email.message}</span>
                            )}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group mb-3">
                            <input
                                type="text"
                                className="form-control theme-light"
                                placeholder={langVar("subject")}
                                {...register("subject", {required: true})}/>
                            {errors.subject && (
                                <span className="invalid-feedback">{langVar("isRequired")}</span>
                            )}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group mb-3">
                          <textarea
                              rows="4"
                              className="form-control theme-light"
                              placeholder={langVar("message")}
                              {...register("content", {required: true})}></textarea>
                            {errors.content && (
                                <span className="invalid-feedback">{langVar("isRequired")}</span>
                            )}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="btn-bar">
                            <button className="px-btn px-btn-white">{langVar("send")}</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Contact;

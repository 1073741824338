import * as Icons from "react-icons/fa";

interface DynamicFaIconProps {
    name: string;
}

const DynamicFaIcon = ({name}: DynamicFaIconProps) => {
    // @ts-ignore
    const IconComponent = Icons["Fa" + name];

    return IconComponent ? <IconComponent/> : "";
};

export default DynamicFaIcon;
import React from "react";
import Header from "../components/header/Header";
import {langVar} from "../index";
import Slider from "../components/slider/Slider";
import About from "../components/about/About";
import Resume from "../components/resume/Resume";
import Footer from "../components/footer/Footer";
import ContactInfo from "../components/contact/ContactInfo";
import Contact from "../components/contact/Contact";
import Portfolio from "../components/portfolio/Portfolio";
import ScrollToTop from "../components/utilities/ScrollToTop";

const Home = () => {
    document.body.classList.add("theme-light");

    return (
        <>
            <ScrollToTop/>
            <div className="main-left">
                <Header/>
                <Slider/>
                <About/>
                <Resume/>

                <section id="work" className="section theme-light dark-bg">
                    <div className="container">
                        <div className="title">
                            <h3>{langVar("0.portfolio")}</h3>
                        </div>
                        <Portfolio/>
                    </div>
                </section>

                <section id="contactus" className="section theme-light dark-bg">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-5 col-xl-4 m-15px-tb"
                                data-aos="fade-right"
                                data-aos-duration="1200">
                                <ContactInfo/>
                            </div>

                            <div
                                className="col-lg-7 ml-auto m-15px-tb"
                                data-aos="fade-right"
                                data-aos-duration="1200"
                                data-aos-delay="200">
                                <div className="contact-form">
                                    <h4>{langVar("0.textMe")}</h4>
                                    <Contact/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer className="footer white">
                    <div className="container">
                        <Footer/>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default Home;

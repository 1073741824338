import React, {useEffect, useState} from "react";
import {formatDate, getActiveLanguageID, langVar} from "../../index";
import {useDirectus} from "react-directus";
import Skills from "../skills/Skills";

const Resume = () => {

    const [newsContent, setNewsContent] = useState([]);
    const [educationContent, setEducationContent] = useState([]);
    const {directus} = useDirectus();

    useEffect(() => {
        const fetchNewsData = async () => {
            return await directus.items("news").readByQuery({
                limit: -1,
                fields: "*.*",
                deep: {
                    "translations": {
                        _filter: {
                            "language_id": {
                                _eq: getActiveLanguageID()
                            }
                        }
                    }
                },
                sort: "-date_created"
            });
        }

        const fetchEducationData = async () => {
            return await directus.items("education").readByQuery({
                limit: -1,
                fields: "*.*",
                deep: {
                    "translations": {
                        _filter: {
                            "language_id": {
                                _eq: getActiveLanguageID()
                            }
                        }
                    }
                },
                sort: "sort"
            });
        }

        fetchEducationData()
            .then(result => {
                let education = [];

                if (result.data)
                    result.data.forEach(educationEntry => {
                        education.push({
                            title: educationEntry.translations.length === 1 ?
                                educationEntry.translations[0].title : "",
                            name: educationEntry.translations.length === 1 ?
                                educationEntry.translations[0].name : "",
                            date: educationEntry.date
                        });
                    });

                setEducationContent(education);
            });

        fetchNewsData()
            .then(result => {
                let news = [];

                if (result.data)
                    result.data.forEach(newsEntry => {
                        news.push({
                            title: newsEntry.translations.length === 1 ?
                                newsEntry.translations[0].title : "",
                            content: newsEntry.translations.length === 1 ?
                                newsEntry.translations[0].content : "",
                            date: formatDate(new Date(newsEntry.date_created))
                        });
                    });

                setNewsContent(news);
            });
    }, [directus]);

    return (
        <>
            <section id="news" className="section">
                <div className="container">
                    <div className="title">
                        <h3>{langVar("0.news")}</h3>
                    </div>

                    <div className="resume-box">
                        {newsContent.map((val, i) => (
                            <div
                                className="resume-row"
                                key={i}
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-delay={100 * i}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="rb-left">
                                            <h6>{val.title}</h6>
                                            <label>{val.date}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="rb-right">
                                            <p dangerouslySetInnerHTML={{__html: val.content}}></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="separated"
                         style={{
                             backgroundImage: `url(${
                                 process.env.PUBLIC_URL + "img/border-dark.png"
                             })`
                         }}></div>

                    <div className="title">
                        <h3>{langVar("0.educationSkills")}</h3>{" "}
                    </div>

                    <div className="row align-items-center">
                        <div
                            className="col-lg-4 m-15px-tb"
                            data-aos="fade-up"
                            data-aos-duration="1200">
                            {educationContent.length > 0 && <>
                                <ul className="aducation-box">
                                    {educationContent.map((val, i) => (
                                        <li key={i}>
                                            <span>{val.date}</span>
                                            <h6>{val.title} </h6>
                                            <p>{val.name}</p>{" "}
                                        </li>
                                    ))}
                                </ul>
                            </>}
                        </div>

                        <div
                            className="col-lg-7 ml-auto m-15px-tb"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                            data-aos-delay="200">
                            <Skills/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Resume;

import React from "react";
import {FaArrowCircleLeft} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {langVar, langVarPrintHtml} from "../index";
import ScrollToTop from "../components/utilities/ScrollToTop";


const PrivacyPolicy = () => {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1);
    }

    return (
        <>
            <ScrollToTop/>
            <section>
                <div className="row full-screen">
                    <div className="col-lg-8 m-3">
                        <div className="inner">
                            <h1 className="display-3 white-color m-15px-b">
                                <a onClick={goBack}><FaArrowCircleLeft/></a>
                            </h1>
                            <div className="m-4">
                                <p className="h3">
                                    {langVar("app.privacyPolicy")}
                                </p>
                                <p className="mt-5"
                                   dangerouslySetInnerHTML={{__html: langVarPrintHtml("app.privacyPolicy.content")}}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicy;

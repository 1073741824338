import React, {useEffect} from "react";
import {
    DATACENTER,
    getActiveLanguageID,
    getPortalUrl,
    getStoreValue,
    getUser,
    langVar,
    langVarPrintHtml,
    logout
} from "../../index";
import {useDirectus} from "react-directus";
import TextLoop from "react-text-loop";

const Slider = () => {
    const conctInfo = {
        phone: getStoreValue("settings", "CONTACT_PHONE"),
        email: getStoreValue("settings", "CONTACT_EMAIL")
    };

    const sliderContent = {
        name: langVar("0.header.name"),
        upperText: langVar("0.header.upperText"),
        designation: langVar("0.header.title"),
        description: langVarPrintHtml("0.header.text")
    };

    const languageList = [];

    for (let key in getStoreValue("languages")) {
        let language = getStoreValue("languages", key);

        let attributes = {
            key: language['id']
        }

        if (getActiveLanguageID() === language['id']) attributes = {...attributes, ...{className: "active"}};

        languageList.push(<li {...attributes}><a data-lang-id={language["id"]} href="#">{language['id']}</a>
        </li>);
    }

    const {directus} = useDirectus();

    useEffect(() => {
        const fetchHeaderImage = async () => {
            return await directus.files.readByQuery({
                filter: {
                    "title": {
                        "_eq": "header"
                    }
                }
            });
        }

        fetchHeaderImage()
            .then(result => {
                if (result.data && result.data.length === 1) {
                    document.getElementById("header-image").style.backgroundImage =
                        `url(${DATACENTER}/assets/${result.data[0].id}?quality=50)`;
                }

            });
    }, [directus]);

    useEffect(() => {
        document.querySelectorAll(".hb-lang ul li a").forEach(value => {
            value.addEventListener('click', async (event) => {
                let selectedLangId = event.target.getAttribute("data-lang-id");

                if (getUser()) {
                    await directus.users.me.update({
                        languageIdentifier: selectedLangId
                    });
                } else {
                    localStorage.setItem("selected_language", selectedLangId);
                }


                window.location.reload();
            });
        })
    }, [directus]);

    return (
        <>
            <section id="home" className="home-banner">
                <div className="hb-top-fixed d-flex">
                    <div className="hb-info">
                        <div>
                            <a href={"tel:" + conctInfo.phone}>{conctInfo.phone}</a>
                            <a href={"mailto:" + conctInfo.email}>
                                {conctInfo.email}
                            </a>
                        </div>
                        <div>
                            {getUser() && <>
                                <a>|</a>
                                <a href={getPortalUrl()}
                                   rel="noreferrer">{langVar("welcomeBackComma")} {getUser().first_name} {getUser().last_name}!</a>
                                <a onClick={logout}>{langVar("auth.logout")}</a>
                            </>}
                        </div>
                    </div>
                    <div className="hb-lang">
                        <ul className="nav">
                            {languageList}
                        </ul>
                    </div>
                </div>

                <div className="container" style={{marginRight: "10%", marginLeft: "10%"}}>
                    <div className="row full-screen align-items-center">
                        <div className="col-lg-7">
                            <div className="type-box">
                                <h6 data-aos="fade-up" data-aos-duration="1200">
                                    {sliderContent.upperText}
                                </h6>
                                <h1
                                    className="font-alt"
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-delay="100">
                                    {sliderContent.name}
                                </h1>
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-delay="200">
                                    <TextLoop>
                                        <p className="loop-text lead">{sliderContent.designation}</p>
                                    </TextLoop>
                                </div>

                                <p
                                    className="desc"
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-delay="300"
                                    dangerouslySetInnerHTML={{__html: sliderContent.description}}>
                                </p>
                                <div
                                    className="mt-4"
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    data-aos-delay="400">
                                    <a className="px-btn px-btn-white"
                                       href={getPortalUrl()}>
                                        {langVar("goToPortal")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="hb-me"
                    id="header-image">
                </div>
            </section>
        </>
    );
};

export default Slider;

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {store} from "state-pool";
import {DirectusProvider} from 'react-directus';
import App from "./App";

export const DATACENTER = "https://api.iuliiaorient.de";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <DirectusProvider apiUrl={DATACENTER}>
        <App/>
    </DirectusProvider>
);

window.addEventListener('storage', (event) => {
    if ((!event.key && localStorage.getItem("auth_token") === null) || event.key === "auth_token")
        window.location.reload();
});

export function getPortalUrl() {
    return window.location.protocol + "//" + window.location.hostname + "/portal";
}

export async function logout() {
    let selected_language = localStorage.getItem("selected_language");
    localStorage.clear();
    if (selected_language) localStorage.setItem("selected_language", selected_language)
    window.location.reload();
}

export function formatDate(date: Date) {
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month = date.getMonth() + 1 < 10 ? "0" + parseInt(String(date.getMonth() + 1)) : date.getMonth() + 1;
    let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

    return day + "." + month + "." + date.getFullYear() + " " + hour + ":" + minute;
}

export function getActiveLanguageID() {
    if (getUser())
        return getUser().languageIdentifier;

    if (localStorage.getItem("selected_language") !== null)
        return localStorage.getItem("selected_language");

    return getStoreValue("settings", "DEFAULT_LANGUAGE_ID");
}

export function getUser() {
    try {
        if (store.getState("user").getValue())
            return JSON.parse(store.getState("user").getValue());
        else
            return null;
    } catch {
        return null;
    }
}


export function getStore() {
    return store;
}

export function getStoreValue(name: string, property: string = "") {
    try {
        return property === "" ? store.getState(name).getValue() : store.getState(name).getValue()[property];
    } catch {
        return "";
    }
}

export function setStoreValue(key: string, value: any) {
    try {
        return store.setState(key, value);
    } catch {
        return "";
    }
}

export function langVar(variable: string) {
    try {
        return store.getState("languageVariables").getValue()[variable].replace(/<br>/g, "") ?? "{" + variable + "}";
    } catch {
        return "{" + variable + "}";
    }
}

export function langVarPrintHtml(variable: string) {
    try {
        return store.getState("languageVariables").getValue()[variable] ?? "{" + variable + "}";
    } catch {
        return "{" + variable + "}";
    }
}

reportWebVitals();

// @ts-nocheck
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Error from "./views/Error";
import Home from "./views/Home";
import LegalNotice from "./views/LegalNotice";
import PrivacyPolicy from "./views/PrivacyPolicy";

const RouteManager = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/legal-notice" element={<LegalNotice/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="*" element={<Error/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default RouteManager;

import React from "react";
import {getStoreValue, langVar} from "../../index";

const ContactInfo = () => {
    const conctInfo = {
        phone: getStoreValue("settings", "CONTACT_PHONE"),
        email: getStoreValue("settings", "CONTACT_EMAIL"),
        address: getStoreValue("settings", "CONTACT_ADDRESS")
    };

    return (
        <>
            <div className="contact-info">
                <h4>{langVar("0.sidebar.contact")}</h4><br/>

                <ul>
                    <li className="media">
                        <i className="icon icon-map"></i>
                        <span className="media-body">
                            {conctInfo.address}
                        </span>
                    </li>

                    <li className="media">
                        <i className="icon icon-envelope"></i>
                        <span className="media-body">
                            <a href={"mailto:" + conctInfo.email}>
                                {conctInfo.email}
                            </a>
                        </span>
                    </li>

                    <li className="media">
                        <i className="icon icon-phone"></i>
                        <span className="media-body"><a href={"tel:" + conctInfo.phone}>{conctInfo.phone}</a></span>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default ContactInfo;

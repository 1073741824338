import React, {useState} from "react";
import Scrollspy from "react-scrollspy";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {FiBriefcase, FiCalendar, FiPhoneOutgoing, FiUser,} from "react-icons/fi";
import {FaHome} from "react-icons/fa";
import {langVar} from "../../index";

const Header = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <>
            <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                    <div className={click ? "active" : ""}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>

            <header className={click ? "header-left menu-open" : "header-left "}>
                <div className="scroll-bar">
                    <div className="hl-top">
                        <div className="hl-logo">
                            <Link to="/">{langVar("0.sidebar.initials")}</Link>
                        </div>
                    </div>

                    <Scrollspy
                        className="nav nav-menu"
                        items={["home", "about", "news", "work", "contactus"]}
                        currentClassName="active"
                        offset={-30}>
                        <li>
                            <a className="nav-link "
                               href="#home"
                               data-tip
                               data-for="HOME"
                               onClick={handleClick}>
                                <FaHome/>
                                <ReactTooltip id="HOME" place="top" type="dark" effect="float">
                                    <span>{langVar("0.sidebar.home")}</span>
                                </ReactTooltip>
                            </a>
                        </li>
                        <li>
                            <a className="nav-link"
                               href="#about"
                               data-tip
                               data-for="ABOUT"
                               onClick={handleClick}>
                                <FiUser/>
                                <ReactTooltip id="ABOUT" place="top" type="dark" effect="float">
                                    <span>{langVar("0.sidebar.about")}</span>
                                </ReactTooltip>
                            </a>
                        </li>
                        <li>
                            <a className="nav-link"
                               href="#news"
                               data-tip
                               data-for="NEWS"
                               onClick={handleClick}>
                                <FiCalendar/>
                                <ReactTooltip id="NEWS" place="top" type="dark" effect="float">
                                    <span>{langVar("0.sidebar.news")}</span>
                                </ReactTooltip>
                            </a>
                        </li>
                        <li>
                            <a className="nav-link"
                               href="#work"
                               data-tip
                               data-for="WORK"
                               onClick={handleClick}>
                                <FiBriefcase/>
                                <ReactTooltip id="WORK" place="top" type="dark" effect="float">
                                    <span>{langVar("0.sidebar.work")}</span>
                                </ReactTooltip>
                            </a>
                        </li>
                        <li>
                            <a className="nav-link"
                               href="#contactus"
                               data-tip
                               data-for="CONTACT"
                               onClick={handleClick}>
                                <FiPhoneOutgoing/>
                                <ReactTooltip
                                    id="CONTACT"
                                    place="top"
                                    type="dark"
                                    effect="float">
                                    <span>{langVar("0.sidebar.contact")}</span>
                                </ReactTooltip>
                            </a>
                        </li>
                    </Scrollspy>
                </div>
            </header>
        </>
    );
};

export default Header;

import React, {useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import Masonry from "react-masonry-css";
import {DATACENTER, getActiveLanguageID, langVar} from "../../index";
import {useDirectus} from "react-directus";

const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
};

const Portfolio = () => {
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const {directus} = useDirectus();

    useEffect(() => {
        const fetchPortfolioData = async () => {
            return await directus.items("portfolio").readByQuery({
                limit: -1,
                fields: "*.*",
                deep: {
                    "translations": {
                        _filter: {
                            "language_id": {
                                _eq: getActiveLanguageID()
                            }
                        }
                    }
                },
                sort: "-date_created"
            });
        }

        fetchPortfolioData()
            .then(result => {
                let categories = [langVar("0.all")];
                let items = [{items: []}];

                if (result.data)
                    result.data.forEach(portfolioObject => {
                        let portfolioItem = {
                            title: portfolioObject.translations.length === 1 ?
                                portfolioObject.translations[0].title : "",
                            subtitle: portfolioObject.translations.length === 1 ?
                                portfolioObject.translations[0].subtitle : "",
                            url: DATACENTER + "/assets/" + portfolioObject.image.id
                        }

                        items[0].items.push(portfolioItem);

                        if (portfolioObject.translations.length === 1)
                            if (portfolioObject.translations[0].category) {
                                if (!categories.includes(portfolioObject.translations[0].category))
                                    categories.push(portfolioObject.translations[0].category);

                                let itemIndex = categories.indexOf(portfolioObject.translations[0].category);

                                if (items.length === itemIndex)
                                    items.push({items: []});

                                items[itemIndex].items.push(portfolioItem);
                            }
                    });

                setCategories(categories);
                setItems(items);
            });
    }, [directus]);

    return (
        <SimpleReactLightbox>
            <div className="portfolio-filter-01">
                <Tabs>
                    <TabList className="filter d-flex flex-wrap justify-content-start">
                        {categories.map((val, i) => (
                            <Tab key={i}>{val}</Tab>
                        ))}
                    </TabList>

                    <SRLWrapper options={{settings: {downloadedFileName: "image"}}}>
                        {items.map((tabContent, i) => (
                            <TabPanel key={i}>
                                <div className="portfolio-content ">
                                    <Masonry
                                        breakpointCols={breakpointColumnsObj}
                                        className="my-masonry-grid"
                                        columnClassName="my-masonry-grid_column">
                                        {tabContent.items.map((val, i) => (
                                            <div
                                                className="portfolio-box-01"
                                                key={i}
                                                data-aos="fade-right"
                                                data-aos-duration="500"
                                                data-aos-delay={100 * i}>
                                                <div className="portfolio-img">
                                                    <div className="portfolio-info">
                                                        <h5>{val.title}</h5>
                                                        <span>{val.subtitle}</span>
                                                    </div>

                                                    <a
                                                        href={val.url}
                                                        className="gallery-link">
                                                        <img
                                                            src={val.url}
                                                            alt={val.title}/>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </Masonry>
                                </div>
                            </TabPanel>
                        ))}
                    </SRLWrapper>
                </Tabs>
            </div>
        </SimpleReactLightbox>
    );
};

export default Portfolio;
